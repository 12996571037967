<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ProgressDialog from './components/progress_dialog'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Choices from "choices.js";

import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'
import {financial} from '@/api/misc'
import moment from 'moment'

/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {
    if (this.expense_type == 'Expense') {
      return {
       
        selected_amount: { required },
        selected_pm: { required },
        post_date : {required},
        supplier_address: { required },
        supplier_name: { required },
        invoice_number: { required },
        selected_total : {required},
        selected_gst   : {required},
        pm_month: {required},
      }
    } else {
      return {
      
        selected_amount: { required },
        selected_total : {required},
        selected_gst   : {required},
        selected_pm: { required },
        post_date : {required},
        supplier_address: {},
        supplier_name: {},
        invoice_number: { required },
        pm_month: {required},
      }
    }

  },


  methods: {

    formSubmit() {

      let data = {
        pm_month  : this.pm_month,
        post_date : this.post_date,
        expenses  : this.listingData
      }
      let data_error = false
      for (let invoice of this.listingData) {
        if (invoice.pm_error || invoice.supplier_error) {
          this.has_data_error = true
          break
        }
      }

      if (data_error == true) {
        this.has_data_error = true
        return
      }
      this.$bvModal.show('ProgressDiloag')
      getPMApi().batch_expense(data).then(res => {
        this.$bvModal.hide('ProgressDiloag')
        if (res.errCode == 0) {
          this.$alertify.message("Add Expense successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error(" Property Add Expense failed:" + res.errCode +"  msg:"+res.msg);
        }
      })


    },



    onPMSelected(evt) {
      this.selected_pm = evt.str_id
    },


    onInvoidPdfUploaded(ev, resp) {

      if (resp[0].errCode == 200) {
        this.file = {
          save_route: resp[0].response.url,
          save_route_key: resp[0].response.key,
          file_name: resp[0].response.filename,
          size: resp[0].response.size,
          type: resp[0].response.type
        }
      }
    },


    onAddProperty() {
      this.$v.$touch()
      if (this.$v.$invalid == true) {
        return
      }

      this.listingData.push({
        expense_id       : this.expense_temp_id,
        code             : this.selected_pm,
        pm_id            : this.selected_pm,
        property_id      : this.selected_pm,
        total            : this.selected_total,
        amount           : this.selected_amount,
        gst              : this.selected_gst,

        supplier_name    : this.supplier_name,
        supplier_address : this.supplier_address,
        supplier_code    : this.supplier_code,
        date             : this.post_date,
        type             : this.expense_type,
        sub_type         : this.expense_sub_type,
        note             : this.note,
        invoice          : this.invoice_number
      })

      this.total_amount += Number(this.selected_amount)

      this.expense_temp_id += 1

      this.$alertify.message("Property Expense Item was Added to List");
    },

    removePMEntry(data) {
      this.listingData = this.listingData.filter(e => data.expense_id != e.expense_id)
      this.total_amount = this.listingData.reduce((a, c) => a +Number(c.amount), 0)
    },


    onSearchPM(str) {
      autoComplete({ types: ['PM'], str: str, selection: ['property_id', 'o_code', 'address'] }).then(res => {
        if (res.errCode == 0) {
          this.searched_pm_data = []
          res.data.map(e => {
            this.searched_pm_data.push(e)
          })
        }
      })
    },

    onSearchSupplier(str) {

      autoComplete({ types: ['SEARCH_SUPPLIER'], str: str }).then(res => {
        if (res.errCode == 0) {
          this.searched_supplier_data = []
          res.data.map(e => {
            this.searched_supplier_data.push({
              str_id   : e.name + '('+e.supplier_code +')',
              supplier : e
            })
          })
        }
        
      })
    },

    onSupplierSelected(evt) {
      this.supplier_name    = evt.supplier.name
      this.supplier_address = evt.supplier.address
      this.supplier_code    = evt.supplier.supplier_code
    },


    onExpenseListUploaded(data, resp) {

      if (resp.errCode == 0) {
        this.has_data_error = false

        
        resp.data.list.map(e => {
          e.property_id       = e.pm_id
          e.expense_id        = this.expense_temp_id
          e.supplier_name     = e.vendor
          e.invoice           = e.invoice_number
          e.date              = e.invoice_date
          e.type              = 'Expense'
          e.sub_type          = e.exp_type
          e.supplier_code     = e.supplier.supplier_code
          e.supplier_name     = e.supplier.name
          e.supplier_address  = e.supplier.address

          this.expense_temp_id += 1
          this.listingData.push(e)
          this.total_amount += Number(e.total)
        })

        if (resp.data.err != 0) {
          this.has_data_error = true
          this.data_err_msg   = resp.data.note
        }
      } else {
        this.has_data_error = true
        this.data_err_msg   = resp.msg
        this.$alertify.error("Upload Failed Ret:"+ resp.errCode+"  err:"+resp.msg);
      }
      
    },


    getValue(item) {
     
      if (item.label =='Amount') {
        return '$' + this.total_amount.toLocaleString()
      } else if (item.label == 'Post Date') {
        return 'Total: '
      } else {
        return ''
      }
    }

    

  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "PM",
          href: "/pm/property_list",
        },
        {
          text: "Post PM Expense",
          active: true,
        },
      ],


      has_data_error : false,
      data_err_msg : '',
      file: {},
      post_date: '',
      expense_type: 'Expense',
      selected_pm: '',
      selected_total   : '',
      selected_amount  : '',
      selected_gst     : '',
      expense_sub_type : 'Cleaning',
      note             : '',

      supplier_code   : '',
      supplier_name   : '',
      supplier_address: '',
      expense_temp_id : 12001,
      invoice_number  : '',
      pm_month: '',

      searched_supplier_data: [],
      listingData: [],
      searched_pm_data: [],
      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 50,
      },

      total_amount : 0
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    vueDropzone: vue2Dropzone,
    VueBootstrapTypeahead,
    ProgressDialog

  },
  mounted() {
    if (this.$route.query.property_id) {
      this.selected_pm = new String(this.$route.query.property_id)
    }

    this.$refs.property.inputValue = this.selected_pm
   // new Choices('#expense_type', { itemSelectText: '' })
    new Choices('#expense_sub_type', { itemSelectText: '' , editItems : true})
    


    let today = moment()
    this.pm_month = today.format("YYYYMM")

  },

  watch: {
    selected_pm(new_pm_str) {
      this.onSearchPM(new_pm_str)
    },

    supplier_name(new_supplier) {
      this.onSearchSupplier(new_supplier)
    },

    selected_amount(new_val) {
      this.selected_gst   = financial(Number(new_val) * 0.05, 2)
      this.selected_total = financial(Number(this.selected_amount) + Number(this.selected_gst), 2)
    },

    selected_gst(new_val) {
      this.selected_total = financial(Number(this.selected_amount) + Number(new_val), 2)
    },
  }
};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <ProgressDialog   />
    </b-modal>

    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-3">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Property Select</h5>
                  </div>
                </div>
                <div class="card-body">


                  <div class="row">
                    <div class="col-md-8">

                      <div class="row">
                        <div class="col-lg-3">
                            <div class="mb-3">
                              <label class="form-label" for="gen-info-name-input">Rent Month</label>
                              <flat-pickr v-model="pm_month" placeholder="Select a month" class="form-control"
                                :config="{ dateFormat: 'Ym', altFormat: 'Y-m', ariaDateFormat: 'Y-m' }"
                                :class="{ 'is-invalid': $v.pm_month.$error }"></flat-pickr>
                              <div v-if="$v.pm_month.$error" class="invalid-feedback">
                                <span v-if="!$v.pm_month.required">This value is required.</span>
                              </div>
                            </div>
                          </div>

                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Post Date</label>
                            <div class="mb-3">

                              <flat-pickr v-model="post_date" placeholder="Select a date" class="form-control"
                                :class="{ 'is-invalid': $v.post_date.$error }"></flat-pickr>
                              <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>


                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Expense Type</label>
                            <select id="expense_sub_type" class="form-control" v-model="expense_sub_type" >
                              <option value="NSF">NSF</option>
                              <option value="Repair">Repair</option>
                               <option value="Insurance">Insurance</option>
                               <option value="Cleaning">Cleaning</option>
                               <option value="Maintaince">Maintaince</option>
                               <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>


                      </div>


                      <div class="row mt-3 mb-3">
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Supplier</label>
                            <vue-bootstrap-typeahead ref="supplier_name_ref" :disabled="expense_type == 'Reimbursement'"
                              v-model="supplier_name" :value="supplier_name" :data="searched_supplier_data"
                              :inputClass="{ 'is-invalid': $v.supplier_name.$error }" :serializer="s => s.str_id"
                              :foramterDisplay="s => s.str_id" @hit="onSupplierSelected($event)" autocomplete="off" />
                          </div>
                        </div>

                        <div class="mb-3 col-lg-7">
                          <label class="form-label" for="gen-info-name-input">Supplier Address</label>
                          <input class="form-control" :disabled="expense_type == 'Reimbursement'"
                            v-model="supplier_address" :class="{ 'is-invalid': $v.supplier_address.$error }" />
                          <div v-if="$v.supplier_address.$error" class="invalid-feedback">
                            <span v-if="!$v.supplier_address.required">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Property</label>
                            <vue-bootstrap-typeahead ref="property" v-model="selected_pm" :value="selected_pm"
                              :data="searched_pm_data" :inputClass="{ 'is-invalid': $v.selected_pm.$error }"
                              :serializer="s => s.str_id" :foramterDisplay="s => s.str_id" @hit="onPMSelected($event)"
                              autocomplete="off" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Amount</label>
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_amount"
                                :class="{ 'is-invalid': $v.selected_amount.$error }" />
                              <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.selected_amount.required">This value is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Gst</label>
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_gst"
                                :class="{ 'is-invalid': $v.selected_gst.$error }" />
                              <div v-if="$v.selected_gst.$error" class="invalid-feedback">
                                <span v-if="!$v.selected_gst.required">This value is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>


                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Total</label>
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_total"
                                :class="{ 'is-invalid': $v.selected_amount.$error }" />
                              <div v-if="$v.selected_total.$error" class="invalid-feedback">
                                <span v-if="!$v.selected_total.required">This value is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Invoice Number</label>
                            <input class="form-control" v-model="invoice_number"
                              :class="{ 'is-invalid': $v.invoice_number.$error }" />
                            <div v-if="$v.invoice_number.$error" class="invalid-feedback">
                              <span v-if="!$v.invoice_number.required">This value is required.</span>
                            </div>
                          </div>
                        </div>


                       

                      </div>

                      <div class="row">
                        <div class="col-lg-8">
                          <label class="form-label" for="gen-info-name-input">Note</label>
                            <div class="mb-3">
                              <input class="form-control" v-model="note" />
                            </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mt-4">
                            <b-button variant="primary" @click="onAddProperty">Add Property</b-button>
                          </div>
                        </div>
                      </div>

                    </div>



                    <div class="col-md-4">
                      <div class="row">

                        <vue-dropzone id="expense-dropzone" :use-custom-slot="true" 
                        :options="{ url: '/adm/pm/expense/upload_invoice', maxFilesize: 10,  maxFiles: 1, paramName: 'invoice', thumbnailHeight : 80, thumbnailWidth : 80}"
                          @vdropzone-success="onExpenseListUploaded">
                          <div >
                            <i class="display-4 text-muted "></i>
                            <h4>Drop expense file to here.</h4>
                          </div>
                        </vue-dropzone>

                      </div>

                      <div class="row mt-3">

                        <vue-dropzone id="invoice-dropzone" :use-custom-slot="true" :options="dropzoneOptions"
                          @vdropzone-success="onInvoidPdfUploaded">
                          <div class="dropzone-custom-content">
                            <i class="display-4 text-muted "></i>
                            <h4>Drop invoide PDF file to here.</h4>
                          </div>
                        </vue-dropzone>

                      </div>
                    </div>

                  </div>

                </div>


              </div>
              <!-- end card -->


              <div class="row">
                <b-alert show variant="danger" v-if="has_data_error"><span v-html="data_err_msg.replaceAll('\n','<br />')"></span> </b-alert>
              </div>


              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Property Rent Detail</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div data-simplebar>
                    <div class="table-responsive mt-3">
                      <div class="table align-middle table-nowrap">
                        <b-table :items="listingData"
                          :fields="['Code', 'Type', 'PostDate', 'Total', 'Invoice', 'Note', 'Supplier', 'Address', 'Remove']"
                          responsive="sm" :per-page="listingData.length" class="table-check" foot-clone>
                          <template #cell(Code)="data">
                            {{ data.item.pm_id }}
                          </template>
                          <template #cell(Total)="data">
                            ${{ data.item.total.toLocaleString() }}
                          </template>
                          <template #cell(PostDate)="data">
                            {{ data.item.date }}
                          </template>
                          <template #cell(Type)="data">
                            {{ data.item.sub_type }}
                          </template>
                          <template #cell(Note)="data">
                            {{ data.item.note }}
                          </template>
                          <template #cell(Invoice)="data">
                            {{ data.item.invoice }}
                          </template>

                          <template #cell(Supplier)="data">
                            {{ data.item.supplier_name }}
                          </template>
                          <template #cell(Address)="data">
                            {{ data.item.supplier_address }}
                          </template>
                          <template #cell(Remove)="data">
                            <div class="row icon-demo-content-10">
                              <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMEntry(data.item)"><i
                                  class="uil-times font-size-4"></i></div>
                            </div>
                          </template>

                          <template #foot()="data">
                            <i>{{ getValue(data) }}</i>
                          </template>


                        </b-table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->


              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name: 'pm-property-list'})"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm"> Submit</button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>

<style scoped>
  #expense-dropzone {
    letter-spacing: 0.2px;
    height: 150px;
  }
</style>